<template>
  <section class="sec-what-next">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-12 align-self-center">
          <div class="form-wrapper">
            <h2 class="h2">Tailored UX design services <br>
              for your business</h2>
            <p class="large">Fill in this form or <a href="mailto:demo@gmail.com">send us an e-mail</a></p>
            <div class="form">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Name*">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="tel" class="form-control" placeholder="Phone number*">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Email*">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Description*">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group submit">
                    <a href="#" class="btn-style no-hover">Contact Us</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 align-self-center">
          <div class="what-next-wrapper">
            <h3 class="h3 white">What’s Next?</h3>
            <ul class="next">
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/what-next-icon-1.svg" alt="icon">
                </div>
                <div class="content">
                  <p class="large white">Our experts will analyze your
                    requirements and contact you within 1-2 business days.</p>
                </div>
              </li>
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/what-next-icon-2.svg" alt="icon">
                </div>
                <div class="content">
                  <p class="large white">Our team will collect all requirements for
                    your project, and if needed, we will sign NDA to ensure that highest
                    level of privacy.</p>
                </div>
              </li>
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/what-next-icon-3.svg" alt="icon">
                </div>
                <div class="content">
                  <p class="large white">We will develop a comprehensive proposal and
                    an action plan for your project with estimates, timelines, CVs, etc.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 order-lg-1 order-2">
          <div class="logo-wrapper">
            <a href="#" class="logo">
              <img src="../assets/images/footer-logo.svg" alt="logo">
            </a>
            <ul class="contact">
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/whatsapp-black-icon.svg" alt="icon">
                </div>
                <a href="tel:(017) 8885055">(017) 8885055</a>
              </li>
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/mail-black-icon.svg" alt="icon">
                </div>
                <a href="mailto:hello@flowdigital.my">hello@flowdigital.my</a>
              </li>
              <li>
                <div class="icon">
                  <img src="../assets/images/icons/address-black-icon.svg" alt="icon">
                </div>
                <p>28-3 Jalan PJU 5/4, Dataran Sunway,
                  Kota Damansara, Petaling Jaya, Selangor, Malaysia.</p>
              </li>
            </ul>
            <div class="rate-us">
              <p class="large black bold">Reach Us</p>
              <ul class="social">
                <li><a href="#" target="_blank"><img src="../assets/images/icons/linkedin-icon.svg" alt="icon"></a></li>
                <li><a href="#" target="_blank"><img src="../assets/images/icons/be-icon.svg" alt="icon"></a></li>
                <li><a href="#" target="_blank"><img src="../assets/images/icons/instgram-icon.svg" alt="icon"></a></li>
                <li><a href="#" target="_blank"><img src="../assets/images/icons/dribble-icon.svg" alt="icon"></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-12 order-lg-2 order-1">
          <div class="link-main-wrapper">
            <div class="link-wrapper">
              <p class="large black bold" @click.prevent="toggleMenu('about')">About <img src="../assets/images/icons/arrow-right-black-icon.svg" class="icon" alt="icon"></p>
              <ul :class="{ 'active': activeMenu === 'about' }">
                <li><a href="#">About Us</a></li>
                <li><a href="#">Life in Flow</a></li>
                <li><a href="#">Career</a></li>
              </ul>
            </div>
            <div class="link-wrapper">
              <p class="large black bold" @click.prevent="toggleMenu('services')">Our Services <img src="../assets/images/icons/arrow-right-black-icon.svg" class="icon" alt="icon"></p>
              <ul :class="{ 'active': activeMenu === 'services' }">
                <li><a href="#">Website Development</a></li>
                <li><a href="#">UI/UX Design</a></li>
                <li><a href="#">Ecommerce Development</a></li>
                <li><a href="#">Mobile App Development</a></li>
                <li><a href="#">CRM Development</a></li>
                <li><a href="#">B2B Development</a></li>
                <li><a href="#">Digital Marketing</a></li>
                <li><a href="#">Paid Social Media</a></li>
                <li><a href="#">Google Marketing</a></li>
                <li><a href="#">Search Engine Optimisation</a></li>
                <li><a href="#">Corporate Branding</a></li>
                <li><a href="#">Logo Design</a></li>
              </ul>
            </div>
            <div class="link-wrapper">
              <p class="large black bold" @click.prevent="toggleMenu('technologies')">Technologies <img src="../assets/images/icons/arrow-right-black-icon.svg" class="icon" alt="icon"></p>
              <ul :class="{ 'active': activeMenu === 'technologies' }">
                <li><a href="#">Wordpress</a></li>
                <li><a href="#">Woocommerce</a></li>
                <li><a href="#">Shopify</a></li>
                <li><a href="#">Webflow</a></li>
                <li><a href="#">Laravel</a></li>
                <li><a href="#">.Net</a></li>
                <li><a href="#">Flutter</a></li>
                <li><a href="#">Kotlin</a></li>
                <li><a href="#">iOS</a></li>
                <li><a href="#">Android</a></li>
                <li><a href="#">Salesforce</a></li>
                <li><a href="#">Commerce Cloud</a></li>
              </ul>
            </div>
            <div class="link-wrapper">
              <p class="large black bold" @click.prevent="toggleMenu('explore')">Explore <img src="../assets/images/icons/arrow-right-black-icon.svg" class="icon" alt="icon"></p>
              <ul :class="{ 'active': activeMenu === 'explore' }">
                <li><a href="#">Our Work</a></li>
                <li><a href="#">Customers</a></li>
                <li><a href="#">Freelancers</a></li>
                <li><a href="#">Flowspace</a></li>
                <li><RouterLink to="/blog">Blog</RouterLink></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div class="bottom-footer">
    <div class="container">
      <p>© Copyright 2024, Flow Digital. All rights reserved.</p>
    </div>
  </div>


  <!-- Project Modal -->
  <div class="modal fade" id="start-project-modal" tabindex="-1" aria-labelledby="start-project-modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <a href="#" class="close-btn" data-bs-dismiss="modal" aria-label="Close">
          <img src="../assets/images/icons/close.svg" alt="close-icon">
        </a>
        <div class="modal-body">
          <div class="form-wrapper">
            <h3>Start a Project</h3>
            <div class="form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Name <span>*</span></label>
                    <input type="text" id="name" class="form-control" placeholder="Enter your name">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Company Name <span>*</span></label>
                    <input type="text" id="name" class="form-control" placeholder="Optional">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Email <span>*</span></label>
                    <input type="email" id="name" class="form-control" placeholder="Enter your email">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Contact Number <span>*</span></label>
                    <input type="tel" id="name" class="form-control" placeholder="Enter your contact number">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="name">Project Description</label>
                    <textarea class="form-control" placeholder="Project Descriptions ..."></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group submit">
                    <a href="#" class="btn-style blue">Submit</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'FooterView',
  data() {
    return {
      activeMenu: '',
    };
  },
  methods: {
    toggleMenu(menu) {
      this.activeMenu = this.activeMenu === menu ? '' : menu;
    }
  },
}
</script>

