<template>
    <div>
      <HeaderView />
  
      <div v-if="error">{{ errorMessage }}</div>
      <div v-else>
        <div v-html="templateHtml"></div>
      </div>
  
      <FooterView />
    </div>
  </template>
  
  <script>
  import HeaderView from "@/components/HeaderView.vue";
  import FooterView from "@/components/FooterView.vue";
  import axios from 'axios';
  
  export default {
    components: {
      HeaderView,
      FooterView
    },
    data() {
      return {
        templateHtml: '',
        error: false,
        errorMessage: ''
      };
    },
    async created() {
      const slug = this.$route.params.slug;
      const previewId = this.$route.params.previewId;

      try {
        let html, css;

        if (previewId) {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/templates/preview/${previewId}`);
          ({ html, css } = response.data.data);
        } else {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/templates/${slug}`);
          ({ html, css } = response.data);
        }

        this.templateHtml = html;
        this.injectCss(css);
      } catch (error) {
        this.error = true;
        this.errorMessage = 'Failed to load template.';
      }
    },
    methods: {
      injectCss(css) {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.appendChild(document.createTextNode(css));
        document.head.appendChild(style);
      }
    }
  };
  </script>
