<template>
  <div class="col-md-3 col-4" v-for="(card, index) in serviceCards" :key="index">
    <div class="demand-card">
      <div class="img-wrapper" :style="{ backgroundColor: card.color }">
        <img :src="card.imgSrc" alt="img">
        <ul class="categories">
          <li v-for="(feature, index) in card.features" :key="index">
            <a href="#">{{ feature }}</a>
          </li>
        </ul>
      </div>
      <div class="content-wrapper">
        <p class="p bold">{{ card.title }}</p>
        <ul class="feature">
          <li v-for="(feature, index) in card.features" :key="index">
            <a href="#">{{ feature }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceCard',
  data() {
    return {
      serviceCards: [
        {
          title: 'Website Development',
          imgSrc: require('@/assets/images/demand-card-img-1.png'),
          features: [
            'Wordpress Development',
            'Webflow Development',
            'CMS Development'
          ],
          color: '#6274ed'
        },
        {
          title: 'UI/UX Design',
          imgSrc: require('@/assets/images/demand-card-img-2.png'),
          features: [
            'Website Design',
            'User Experience (UX)',
            'UX Audit',
            'User Interface (UI)',
            'App Design',
            'Landing Page Design'
          ],
          color: '#1b2234'
        },
        {
          title: 'Mobile App Development',
          imgSrc: require('@/assets/images/demand-card-img-3.png'),
          features: [
            'Hybrid',
            'Progressive Web App (PWA)',
            'Flutter',
            'iOS',
            'Android'
          ],
          color: '#ffc301'
        },
        {
          title: 'Ecommerce Development',
          imgSrc: require('@/assets/images/demand-card-img-4.png'),
          features: [
            'Shopify Development',
            'Woocommerce Development',
            'Magneto Development',
            'Opencart Development'
          ],
          color: '#fad3e3'
        },
        {
          title: 'Custom Service Development',
          imgSrc: require('@/assets/images/demand-card-img-5.png'),
          features: [
            'Wordpress Development',
            'Webflow Development',
            'CMS Development'
          ],
          color: '#3467ff'
        },
        {
          title: 'Digital Marketing',
          imgSrc: require('@/assets/images/demand-card-img-6.png'),
          features: [
            'SEO',
            'Google Ads',
            'CMS'
          ],
          color: '#060f17'
        },
        {
          title: 'Social Media Marketing',
          imgSrc: require('@/assets/images/demand-card-img-7.png'),
          features: [
            'Social Media Management',
            'Video Content',
            'Tiktok',
            'Paid Social Media'
          ],
          color: '#6274ed'
        },
        {
          title: 'Corporate Branding',
          imgSrc: require('@/assets/images/demand-card-img-8.png'),
          features: [
            'Wordpress Development',
            'Webflow Development',
            'CMS Development'
          ],
          color: '#ff4f28'
        }
      ]
    };
  },
}
</script>
