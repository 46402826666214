import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/main.scss'

import 'vue3-carousel/dist/carousel.css'
import './assets/scss/marque-slider.css'

import Vue3Lottie from 'vue3-lottie'

createApp(App).use(store).use(router).use(Vue3Lottie).mount('#app')
