<template>
  <header :class="$route.path === '/' ? 'light-shadow' : ''">
    <div class="header-layer" :class="{'active' : isMenuOpen}" @click="isMenuOpen = false; activeMenu = '';"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-9 col-8 align-self-center">
          <div class="header-wrapper">
            <RouterLink to="/" class="logo">
              <img src="../assets/images/logo.svg" alt="Logo">
            </RouterLink>
            <div class="navbar-menu" :class="{'active' : isMenuOpen}">
              <a href="#" class="logo">
                <img src="../assets/images/logo.svg" alt="Logo">
              </a>
              <ul class="menu">
                <li :class="{ 'active': activeMenu === 'about' }">
                  <a href="#" @click.prevent="toggleMenu('about')">About</a>
                  <ul class="submenu">
                    <li><RouterLink to="/company">Company</RouterLink></li>
                    <li><RouterLink to="/life-flow">Life at Flow</RouterLink></li>
                    <li><RouterLink to="/portfolio">Career</RouterLink></li>
                  </ul>
                </li>
                <li :class="{ 'active': activeMenu === 'service' }">
                  <a href="#" @click.prevent="toggleMenu('service')">Service</a>
                  <ul class="submenu categories">
                    <li :class="{ 'show-category': categoryItem === 'ui-ux' }">
                      <RouterLink to="/service" @click="setCategoryItem('ui-ux')">UI/UX Design</RouterLink>
                      <div class="category-menu">
                        <div class="title-wrapper">
                          <a href="#" class="back-icon" @click.prevent="clearCategoryItem">
                            <img src="../assets/images/icons/arrow-left-black-icon.svg" alt="icon">
                          </a>
                          <p class="p">UX/ UI Design</p>
                        </div>
                        <ul class="category-list">
                          <li><RouterLink to="#">Website Design</RouterLink></li>
                          <li><RouterLink to="#">User Experience (UX)</RouterLink></li>
                          <li><RouterLink to="#">UX Audit</RouterLink></li>
                          <li><RouterLink to="#">User Interface (UI)</RouterLink></li>
                          <li><RouterLink to="#">App Design</RouterLink></li>
                          <li><RouterLink to="#">Landing Page Design</RouterLink></li>
                        </ul>
                      </div>
                    </li>
                    <li :class="{ 'show-category': categoryItem === 'mobile-app-development' }">
                      <RouterLink to="/service" @click="setCategoryItem('mobile-app-development')">Mobile App Development</RouterLink>
                      <div class="category-menu">
                        <div class="title-wrapper">
                          <a href="#" class="back-icon" @click.prevent="clearCategoryItem">
                            <img src="../assets/images/icons/arrow-left-black-icon.svg" alt="icon">
                          </a>
                          <p class="p">Mobile App Development</p>
                        </div>
                        <ul class="category-list">
                          <li><RouterLink to="#">Website Design</RouterLink></li>
                          <li><RouterLink to="#">User Experience (UX)</RouterLink></li>
                          <li><RouterLink to="#">UX Audit</RouterLink></li>
                          <li><RouterLink to="#">User Interface (UI)</RouterLink></li>
                          <li><RouterLink to="#">App Design</RouterLink></li>
                          <li><RouterLink to="#">Landing Page Design</RouterLink></li>
                        </ul>
                      </div>
                    </li>
                    <li :class="{ 'show-category': categoryItem === 'ecommerce-development' }">
                      <RouterLink to="/service" @click="setCategoryItem('ecommerce-development')">Ecommerce Development</RouterLink>
                      <div class="category-menu">
                        <div class="title-wrapper">
                          <a href="#" class="back-icon" @click.prevent="clearCategoryItem">
                            <img src="../assets/images/icons/arrow-left-black-icon.svg" alt="icon">
                          </a>
                          <p class="p">Ecommerce Development</p>
                        </div>
                        <ul class="category-list">
                          <li><RouterLink to="#">Website Design</RouterLink></li>
                          <li><RouterLink to="#">User Experience (UX)</RouterLink></li>
                          <li><RouterLink to="#">UX Audit</RouterLink></li>
                          <li><RouterLink to="#">User Interface (UI)</RouterLink></li>
                          <li><RouterLink to="#">App Design</RouterLink></li>
                          <li><RouterLink to="#">Landing Page Design</RouterLink></li>
                        </ul>
                      </div>
                    </li>
                    <li :class="{ 'show-category': categoryItem === 'custom-service-development' }">
                      <RouterLink to="/service" @click="setCategoryItem('custom-service-development')">Custom Service Development</RouterLink>
                      <div class="category-menu">
                        <div class="title-wrapper">
                          <a href="#" class="back-icon" @click.prevent="clearCategoryItem">
                            <img src="../assets/images/icons/arrow-left-black-icon.svg" alt="icon">
                          </a>
                          <p class="p">Custom Service Development</p>
                        </div>
                        <ul class="category-list">
                          <li><RouterLink to="#">Website Design</RouterLink></li>
                          <li><RouterLink to="#">User Experience (UX)</RouterLink></li>
                          <li><RouterLink to="#">UX Audit</RouterLink></li>
                          <li><RouterLink to="#">User Interface (UI)</RouterLink></li>
                          <li><RouterLink to="#">App Design</RouterLink></li>
                          <li><RouterLink to="#">Landing Page Design</RouterLink></li>
                        </ul>
                      </div>
                    </li>
                    <li :class="{ 'show-category': categoryItem === 'digital-marketing' }">
                      <RouterLink to="/service" @click="setCategoryItem('digital-marketing')">Digital Marketing</RouterLink>
                      <div class="category-menu">
                        <div class="title-wrapper">
                          <a href="#" class="back-icon" @click.prevent="clearCategoryItem">
                            <img src="../assets/images/icons/arrow-left-black-icon.svg" alt="icon">
                          </a>
                          <p class="p">Digital Marketing</p>
                        </div>
                        <ul class="category-list">
                          <li><RouterLink to="#">Website Design</RouterLink></li>
                          <li><RouterLink to="#">User Experience (UX)</RouterLink></li>
                          <li><RouterLink to="#">UX Audit</RouterLink></li>
                          <li><RouterLink to="#">User Interface (UI)</RouterLink></li>
                          <li><RouterLink to="#">App Design</RouterLink></li>
                          <li><RouterLink to="#">Landing Page Design</RouterLink></li>
                        </ul>
                      </div>
                    </li>
                    <li :class="{ 'show-category': categoryItem === 'social-marketing' }">
                      <RouterLink to="/service" @click="setCategoryItem('social-marketing')">Social Media Marketing</RouterLink>
                      <div class="category-menu">
                        <div class="title-wrapper">
                          <a href="#" class="back-icon" @click.prevent="clearCategoryItem">
                            <img src="../assets/images/icons/arrow-left-black-icon.svg" alt="icon">
                          </a>
                          <p class="p">Social Media Marketing</p>
                        </div>
                        <ul class="category-list">
                          <li><RouterLink to="#">Website Design</RouterLink></li>
                          <li><RouterLink to="#">User Experience (UX)</RouterLink></li>
                          <li><RouterLink to="#">UX Audit</RouterLink></li>
                          <li><RouterLink to="#">User Interface (UI)</RouterLink></li>
                          <li><RouterLink to="#">App Design</RouterLink></li>
                          <li><RouterLink to="#">Landing Page Design</RouterLink></li>
                        </ul>
                      </div>
                    </li>
                    <li :class="{ 'show-category': categoryItem === 'corporate-branding' }">
                      <RouterLink to="/service" @click="setCategoryItem('corporate-branding')">Corporate Branding</RouterLink>
                      <div class="category-menu">
                        <div class="title-wrapper">
                          <a href="#" class="back-icon" @click.prevent="clearCategoryItem">
                            <img src="../assets/images/icons/arrow-left-black-icon.svg" alt="icon">
                          </a>
                          <p class="p">Corporate Branding</p>
                        </div>
                        <ul class="category-list">
                          <li><RouterLink to="#">Website Design</RouterLink></li>
                          <li><RouterLink to="#">User Experience (UX)</RouterLink></li>
                          <li><RouterLink to="#">UX Audit</RouterLink></li>
                          <li><RouterLink to="#">User Interface (UI)</RouterLink></li>
                          <li><RouterLink to="#">App Design</RouterLink></li>
                          <li><RouterLink to="#">Landing Page Design</RouterLink></li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </li>
                <li :class="{ 'active': activeMenu === 'technologies' }">
                  <a href="#" @click.prevent="toggleMenu('technologies')">Technologies</a>
                  <ul class="submenu">
                    <li><a href="#">Wordpress</a></li>
                    <li><a href="#">WooCommerce</a></li>
                    <li><a href="#">Shopify</a></li>
                    <li><a href="#">Webflow</a></li>
                    <li><a href="#">Magento</a></li>
                    <li><a href="#">Laravel</a></li>
                    <li><a href="#">Flutter</a></li>
                    <li><a href="#">Vue.js</a></li>
                  </ul>
                </li>
                <li :class="{ 'active': activeMenu === 'customers' }">
                  <a href="#" @click.prevent="toggleMenu('customers')">Customers</a>
                  <ul class="submenu">
                    <li><RouterLink to="/customer">Our Customers</RouterLink></li>
                    <li><RouterLink to="/case-study">Case Studies</RouterLink></li>
                    <li><a href="#">Customers Testimonials</a></li>
                  </ul>
                </li>
                <li class="no-arrow"><RouterLink to="/blog">Blog</RouterLink></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-4 align-self-center">
          <div class="hamburger-menu">
            <a href="#" @click="isMenuOpen = true;"><img src="../assets/images/icons/hamburger-icon.svg" alt="icon"></a>
          </div>
          <div class="btn-wrapper">
            <a href="#" class="btn-style btn-shadow" data-bs-toggle="modal" data-bs-target="#start-project-modal">Start A Project</a>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="mega-menu" :class="{ 'active': activeMenu}">
    <div class="mega-menu-overlay" @click="activeMenu = '';"></div>
    <div class="container">
      <div class="mega-menu-main">
        <div :class="['mega-menu-wrapper', 'about-mega-menu', { 'active': activeMenu === 'about' }]">
          <div class="mega-column-wrapper">
            <div class="mega-column-card">
              <RouterLink to="/company" class="column-card mb-12">
                <div class="column-header">
                  <div class="title-wrapper">
                    <div class="icon">
                      <img src="../assets/images/icons/compnay-icon.svg" alt="icon">
                    </div>
                    <p class="large bold black">Company</p>
                  </div>
                  <div class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                </div>
                <div class="column-body">
                  <p class="small">80% of our clients continue their services
                    with us after one project, highlighting their
                    satisfaction and our excellence.</p>
                </div>
              </RouterLink>
              <RouterLink to="/life-flow" class="column-card">
                <div class="column-header">
                  <div class="title-wrapper">
                    <div class="icon">
                      <img src="../assets/images/icons/life-flow-icon.svg" alt="icon">
                    </div>
                    <p class="large bold black">Life at Flow</p>
                  </div>
                  <div  class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                </div>
                <div class="column-body">
                  <p class="small">At Flow, our dynamic and supportive environment fosters
                    creativity and collaboration, ensuring our team thrives
                    both professionally and personally.</p>
                </div>
              </RouterLink>
            </div>
            <div class="mega-column-card">
              <RouterLink to="/project" class="column-card h-100 pb-150">
                <div class="column-header">
                  <div class="title-wrapper">
                    <div class="icon">
                      <img src="../assets/images/icons/career-icon.svg" alt="icon">
                    </div>
                    <p class="large bold black">Career</p>
                  </div>
                  <div class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                </div>
                <div class="column-body">
                  <p class="small">Join Flow to advance your career with exciting opportunities for growth, continuous learning, and impactful work that makes a difference.</p>
                  <div class="career-img">
                    <img src="../assets/images/icons/popup-career-img.png" alt="img">
                  </div>
                </div>
              </RouterLink>
            </div>
          </div>
        </div>
        <div :class="['mega-menu-wrapper', 'customers-mega-menu', { 'active': activeMenu === 'customers' }]">
          <div class="mega-column-wrapper">
            <div class="mega-column-card">
              <RouterLink to="/customer" class="column-card mb-12">
                <div class="column-header">
                  <div class="title-wrapper">
                    <div class="icon">
                      <img src="../assets/images/icons/compnay-icon.svg" alt="icon">
                    </div>
                    <p class="large bold black">Our Customers</p>
                  </div>
                  <div class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                </div>
                <div class="column-body">
                  <p class="small">We build strong, lasting relationships by
                    delivering exceptional service and tailored
                    solutions that meet their unique needs.</p>
                </div>
              </RouterLink>
              <RouterLink to="/case-study" class="column-card">
                <div class="column-header">
                  <div class="title-wrapper">
                    <div class="icon">
                      <img src="../assets/images/icons/life-flow-icon.svg" alt="icon">
                    </div>
                    <p class="large bold black">Case Studies</p>
                  </div>
                  <div class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                </div>
                <div class="column-body">
                  <p class="small">Real-world success stories highlighting
                    clients' achievements with our innovative
                    solutions and dedicated support.</p>
                </div>
              </RouterLink>
            </div>
            <div class="mega-column-card">
              <RouterLink to="/customer" class="column-card">
                <div class="column-header">
                  <div class="title-wrapper">
                    <p class="large bold black">Customers <br>
                      Testimonials</p>
                  </div>
                  <div class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></div>
                </div>
                <div class="column-body">
                  <p class="small">Hear directly from our clients about their positive
                    experiences and the impactful results they've achieved
                    through our services.</p>
                </div>
              </RouterLink>
              <div class="chat-card">
                <div class="icon">
                  <img src="../assets/images/icons/chat-green-icon.svg" alt="icon">
                </div>
                <div class="content">
                  <p class="small">We can collaborate and develop
                    on-demand software or tools as
                    per your needs.</p>
                  <a href="#" class="link small">Start a Project <img src="../assets/images/icons/arrow-right-blue-icon.svg" alt="icon"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="['mega-menu-wrapper', 'service-mega-menu', { 'active': activeMenu === 'service' }]">
          <div class="column-tab-wrapper">
            <div class="column-tab-card">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="web-tab" data-bs-toggle="tab" data-bs-target="#web"
                          type="button" role="tab" aria-controls="web" aria-selected="true">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/service-web-icon.svg" alt="" class="icon">
                    Website Development
                    </span>
                    <RouterLink to="/service" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></RouterLink>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="graphic-tab" data-bs-toggle="tab" data-bs-target="#graphic"
                          type="button" role="tab" aria-controls="graphic" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/service-graphic-icon.svg" alt="" class="icon">
                    UI/UX Design
                    </span>
                    <RouterLink to="/service" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></RouterLink>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="mobile-tab" data-bs-toggle="tab" data-bs-target="#mobile"
                          type="button" role="tab" aria-controls="mobile" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/service-mobile-app-icon.svg" alt="" class="icon">
                    Mobile App Development
                    </span>
                    <RouterLink to="/service" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></RouterLink>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="ecommerce-tab" data-bs-toggle="tab" data-bs-target="#ecommerce"
                          type="button" role="tab" aria-controls="ecommerce" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/service-ecommerce-icon.svg" alt="" class="icon">
                    Ecommerce Development
                    </span>
                    <RouterLink to="/service" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></RouterLink>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="custom-tab" data-bs-toggle="tab" data-bs-target="#custom"
                          type="button" role="tab" aria-controls="custom" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/service-custom-service-icon.svg" alt="" class="icon">
                    Custom Service Development
                    </span>
                    <RouterLink to="/service" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></RouterLink>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="digital-tab" data-bs-toggle="tab" data-bs-target="#digital"
                          type="button" role="tab" aria-controls="digital" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/service-digital-icon.svg" alt="" class="icon">
                    Digital Marketing
                    </span>
                    <RouterLink to="/service" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></RouterLink>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="social-tab" data-bs-toggle="tab" data-bs-target="#social"
                          type="button" role="tab" aria-controls="social" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/service-social-icon.svg" alt="" class="icon">
                    Social Media Marketing
                    </span>
                    <RouterLink to="/service" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></RouterLink>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="branding-tab" data-bs-toggle="tab" data-bs-target="#branding"
                          type="button" role="tab" aria-controls="branding" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/service-corporate-icon.svg" alt="" class="icon">
                    Corporate Branding
                    </span>
                    <RouterLink to="/service" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></RouterLink>
                  </button>
                </li>
              </ul>
            </div>
            <div class="column-tab-card">
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="web" role="tabpanel" aria-labelledby="web-tab">
                  <div class="service-detail-card">
                    <p class="large title">Website Development</p>
                    <ul class="service">
                      <li><a href="#">Website Design</a></li>
                      <li><a href="#">User Experience (UX)</a></li>
                      <li><a href="#">UX Audit </a></li>
                      <li><a href="#">User Interface (UI)</a></li>
                      <li><a href="#">App Design</a></li>
                      <li><a href="#">Landing Page Design</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="graphic" role="tabpanel" aria-labelledby="graphic-tab" tabindex="0">
                  <div class="service-detail-card">
                    <p class="large title">UI/UX Design</p>
                    <ul class="service">
                      <li><a href="#">Website Design</a></li>
                      <li><a href="#">User Experience (UX)</a></li>
                      <li><a href="#">UX Audit </a></li>
                      <li><a href="#">User Interface (UI)</a></li>
                      <li><a href="#">App Design</a></li>
                      <li><a href="#">Landing Page Design</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="mobile" role="tabpanel" aria-labelledby="mobile-tab" tabindex="0">
                  <div class="service-detail-card">
                    <p class="large title">Mobile App Development</p>
                    <ul class="service">
                      <li><a href="#">Website Design</a></li>
                      <li><a href="#">User Experience (UX)</a></li>
                      <li><a href="#">UX Audit </a></li>
                      <li><a href="#">User Interface (UI)</a></li>
                      <li><a href="#">App Design</a></li>
                      <li><a href="#">Landing Page Design</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="ecommerce" role="tabpanel" aria-labelledby="ecommerce-tab" tabindex="0">
                  <div class="service-detail-card">
                    <p class="large title">Ecommerce Development</p>
                    <ul class="service">
                      <li><a href="#">Website Design</a></li>
                      <li><a href="#">User Experience (UX)</a></li>
                      <li><a href="#">UX Audit </a></li>
                      <li><a href="#">User Interface (UI)</a></li>
                      <li><a href="#">App Design</a></li>
                      <li><a href="#">Landing Page Design</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="custom" role="tabpanel" aria-labelledby="custom-tab" tabindex="0">
                  <div class="service-detail-card">
                    <p class="large title">Custom Service Development</p>
                    <ul class="service">
                      <li><a href="#">Website Design</a></li>
                      <li><a href="#">User Experience (UX)</a></li>
                      <li><a href="#">UX Audit </a></li>
                      <li><a href="#">User Interface (UI)</a></li>
                      <li><a href="#">App Design</a></li>
                      <li><a href="#">Landing Page Design</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="digital" role="tabpanel" aria-labelledby="digital-tab" tabindex="0">
                  <div class="service-detail-card">
                    <p class="large title">Digital Marketing</p>
                    <ul class="service">
                      <li><a href="#">Website Design</a></li>
                      <li><a href="#">User Experience (UX)</a></li>
                      <li><a href="#">UX Audit </a></li>
                      <li><a href="#">User Interface (UI)</a></li>
                      <li><a href="#">App Design</a></li>
                      <li><a href="#">Landing Page Design</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="social" role="tabpanel" aria-labelledby="social-tab" tabindex="0">
                  <div class="service-detail-card">
                    <p class="large title">Social Media Marketing</p>
                    <ul class="service">
                      <li><a href="#">Website Design</a></li>
                      <li><a href="#">User Experience (UX)</a></li>
                      <li><a href="#">UX Audit </a></li>
                      <li><a href="#">User Interface (UI)</a></li>
                      <li><a href="#">App Design</a></li>
                      <li><a href="#">Landing Page Design</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="branding" role="tabpanel" aria-labelledby="branding-tab" tabindex="0">
                  <div class="service-detail-card">
                    <p class="large title">Corporate Branding</p>
                    <ul class="service">
                      <li><a href="#">Website Design</a></li>
                      <li><a href="#">User Experience (UX)</a></li>
                      <li><a href="#">UX Audit </a></li>
                      <li><a href="#">User Interface (UI)</a></li>
                      <li><a href="#">App Design</a></li>
                      <li><a href="#">Landing Page Design</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="['mega-menu-wrapper', 'technologies-mega-menu', { 'active': activeMenu === 'technologies' }]">
          <div class="column-tab-wrapper technology-tab">
            <div class="column-tab-card">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="wordpress-tab" data-bs-toggle="tab" data-bs-target="#wordpress"
                          type="button" role="tab" aria-controls="wordpress" aria-selected="true">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/wordpress-icon.svg" alt="" class="icon">
                    Wordpress
                    </span>
                    <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="woocommerce-tab" data-bs-toggle="tab" data-bs-target="#woocommerce"
                          type="button" role="tab" aria-controls="woocommerce" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/woocommerce-icon.svg" alt="" class="icon">
                    WooCommerce
                    </span>
                    <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="shopify-tab" data-bs-toggle="tab" data-bs-target="#shopify"
                          type="button" role="tab" aria-controls="shopify" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/shopify-icon.svg" alt="" class="icon">
                    Shopify
                    </span>
                    <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="webflow-tab" data-bs-toggle="tab" data-bs-target="#webflow"
                          type="button" role="tab" aria-controls="webflow" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/webflow-icon.svg" alt="" class="icon">
                    Webflow
                    </span>
                    <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="magento-tab" data-bs-toggle="tab" data-bs-target="#magento"
                          type="button" role="tab" aria-controls="magento" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/magneto-icon.svg" alt="" class="icon">
                    Magento
                    </span>
                    <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="laravel-tab" data-bs-toggle="tab" data-bs-target="#laravel"
                          type="button" role="tab" aria-controls="laravel" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/laravel-icon.svg" alt="" class="icon">
                    Laravel
                    </span>
                    <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="flutter-tab" data-bs-toggle="tab" data-bs-target="#flutter"
                          type="button" role="tab" aria-controls="flutter" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/flutter-icon.svg" alt="" class="icon">
                    Flutter
                    </span>
                    <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="vue-tab" data-bs-toggle="tab" data-bs-target="#vue"
                          type="button" role="tab" aria-controls="vue" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/vue-icon.svg" alt="" class="icon">
                    Vue.js
                    </span>
                    <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a>
                  </button>
                </li>

                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="wordpress-tab" data-bs-toggle="tab" data-bs-target="#wordpress"
                          type="button" role="tab" aria-controls="wordpress" aria-selected="true">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/wordpress-icon.svg" alt="" class="icon">
                    Wordpress
                    </span>
                    <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="woocommerce-tab" data-bs-toggle="tab" data-bs-target="#woocommerce"
                          type="button" role="tab" aria-controls="woocommerce" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/woocommerce-icon.svg" alt="" class="icon">
                    WooCommerce
                    </span>
                    <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="shopify-tab" data-bs-toggle="tab" data-bs-target="#shopify"
                          type="button" role="tab" aria-controls="shopify" aria-selected="false">
                    <span class="name-wrapper">
                      <img src="../assets/images/icons/shopify-icon.svg" alt="" class="icon">
                    Shopify
                    </span>
                    <a href="#" class="link"><img src="../assets/images/icons/arrow-right-circle-icon.svg" alt="icon"></a>
                  </button>
                </li>
              </ul>
            </div>
            <div class="column-tab-card">
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="wordpress" role="tabpanel" aria-labelledby="wordpress-tab">
                  <div class="service-detail-card technology-detail-card">
                    <p class="large title">WooCommerce</p>
                    <ul class="service">
                      <li><a href="#">WooCommerce Development</a></li>
                      <li><a href="#">Woo Commerce Market Development Service</a></li>
                      <li><a href="#">WooCommerce POS Development Servcie</a></li>
                      <li><a href="#">WooCommerce Headless Development</a></li>
                      <li><a href="#">WooCommerce React Development Service</a></li>
                      <li><a href="#">Hire WooCommerce Developer</a></li>
                      <li><a href="#">WooCommerce Mobile App Developer</a></li>
                      <li><a href="#">WordPress Theme Development Service</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="woocommerce" role="tabpanel" aria-labelledby="woocommerce-tab" tabindex="0">
                  <div class="service-detail-card technology-detail-card">
                    <p class="large title">WooCommerce</p>
                    <ul class="service">
                      <li><a href="#">WooCommerce Development</a></li>
                      <li><a href="#">Woo Commerce Market Development Service</a></li>
                      <li><a href="#">WooCommerce POS Development Servcie</a></li>
                      <li><a href="#">WooCommerce Headless Development</a></li>
                      <li><a href="#">WooCommerce React Development Service</a></li>
                      <li><a href="#">Hire WooCommerce Developer</a></li>
                      <li><a href="#">WooCommerce Mobile App Developer</a></li>
                      <li><a href="#">WordPress Theme Development Service</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="shopify" role="tabpanel" aria-labelledby="shopify-tab" tabindex="0">
                  <div class="service-detail-card technology-detail-card">
                    <p class="large title">WooCommerce</p>
                    <ul class="service">
                      <li><a href="#">WooCommerce Development</a></li>
                      <li><a href="#">Woo Commerce Market Development Service</a></li>
                      <li><a href="#">WooCommerce POS Development Servcie</a></li>
                      <li><a href="#">WooCommerce Headless Development</a></li>
                      <li><a href="#">WooCommerce React Development Service</a></li>
                      <li><a href="#">Hire WooCommerce Developer</a></li>
                      <li><a href="#">WooCommerce Mobile App Developer</a></li>
                      <li><a href="#">WordPress Theme Development Service</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="webflow" role="tabpanel" aria-labelledby="webflow-tab" tabindex="0">
                  <div class="service-detail-card technology-detail-card">
                    <p class="large title">WooCommerce</p>
                    <ul class="service">
                      <li><a href="#">WooCommerce Development</a></li>
                      <li><a href="#">Woo Commerce Market Development Service</a></li>
                      <li><a href="#">WooCommerce POS Development Servcie</a></li>
                      <li><a href="#">WooCommerce Headless Development</a></li>
                      <li><a href="#">WooCommerce React Development Service</a></li>
                      <li><a href="#">Hire WooCommerce Developer</a></li>
                      <li><a href="#">WooCommerce Mobile App Developer</a></li>
                      <li><a href="#">WordPress Theme Development Service</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="magento" role="tabpanel" aria-labelledby="magento-tab" tabindex="0">
                  <div class="service-detail-card technology-detail-card">
                    <p class="large title">WooCommerce</p>
                    <ul class="service">
                      <li><a href="#">WooCommerce Development</a></li>
                      <li><a href="#">Woo Commerce Market Development Service</a></li>
                      <li><a href="#">WooCommerce POS Development Servcie</a></li>
                      <li><a href="#">WooCommerce Headless Development</a></li>
                      <li><a href="#">WooCommerce React Development Service</a></li>
                      <li><a href="#">Hire WooCommerce Developer</a></li>
                      <li><a href="#">WooCommerce Mobile App Developer</a></li>
                      <li><a href="#">WordPress Theme Development Service</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="laravel" role="tabpanel" aria-labelledby="laravel-tab" tabindex="0">
                  <div class="service-detail-card technology-detail-card">
                    <p class="large title">WooCommerce</p>
                    <ul class="service">
                      <li><a href="#">WooCommerce Development</a></li>
                      <li><a href="#">Woo Commerce Market Development Service</a></li>
                      <li><a href="#">WooCommerce POS Development Servcie</a></li>
                      <li><a href="#">WooCommerce Headless Development</a></li>
                      <li><a href="#">WooCommerce React Development Service</a></li>
                      <li><a href="#">Hire WooCommerce Developer</a></li>
                      <li><a href="#">WooCommerce Mobile App Developer</a></li>
                      <li><a href="#">WordPress Theme Development Service</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="flutter" role="tabpanel" aria-labelledby="flutter-tab" tabindex="0">
                  <div class="service-detail-card technology-detail-card">
                    <p class="large title">WooCommerce</p>
                    <ul class="service">
                      <li><a href="#">WooCommerce Development</a></li>
                      <li><a href="#">Woo Commerce Market Development Service</a></li>
                      <li><a href="#">WooCommerce POS Development Servcie</a></li>
                      <li><a href="#">WooCommerce Headless Development</a></li>
                      <li><a href="#">WooCommerce React Development Service</a></li>
                      <li><a href="#">Hire WooCommerce Developer</a></li>
                      <li><a href="#">WooCommerce Mobile App Developer</a></li>
                      <li><a href="#">WordPress Theme Development Service</a></li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="vue" role="tabpanel" aria-labelledby="vue-tab" tabindex="0">
                  <div class="service-detail-card technology-detail-card">
                    <p class="large title">WooCommerce</p>
                    <ul class="service">
                      <li><a href="#">WooCommerce Development</a></li>
                      <li><a href="#">Woo Commerce Market Development Service</a></li>
                      <li><a href="#">WooCommerce POS Development Servcie</a></li>
                      <li><a href="#">WooCommerce Headless Development</a></li>
                      <li><a href="#">WooCommerce React Development Service</a></li>
                      <li><a href="#">Hire WooCommerce Developer</a></li>
                      <li><a href="#">WooCommerce Mobile App Developer</a></li>
                      <li><a href="#">WordPress Theme Development Service</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderView',
  data() {
    return {
      activeMenu: '',
      isMenuOpen: false,
      categoryItem: null,
    };
  },
  methods: {
    toggleMenu(menu) {
      this.activeMenu = this.activeMenu === menu ? '' : menu;
    },
    setCategoryItem(item) {
      this.categoryItem = item;
    },
    clearCategoryItem() {
      console.log(this.categoryItem, "clearCategoryItem called");
      this.categoryItem = null;
    }
  },
  watch: {
    activeMenu(newVal) {
      if (newVal) {
        document.body.classList.add('overflow');
      } else {
        document.body.classList.remove('overflow');
      }
    },
    isMenuOpen(newVal) {
      if (newVal) {
        document.body.classList.add('overflow');
      } else {
        document.body.classList.remove('overflow');
      }
    },
  },
  mounted() {
    document.body.classList.remove('overflow');
  }
}
</script>

